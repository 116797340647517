<template>
  <g :fill="color" fill-rule="evenodd">
    <defs>
      <path
        id="icon_discount_a"
        d="M6.5 0c.513 0 .929.398.929.889l-.001 12.076 3.987-3.816c.332-.318.854-.344 1.218-.08l.095.08c.363.347.363.91 0 1.257L7.157 15.74c-.026.024-.053.047-.082.07l-.048.033-.047.03-.045.024c-.022.011-.045.022-.07.031l-.024.01c-.025.01-.05.018-.076.025l-.043.011c-.071.017-.145.026-.222.026-.077 0-.15-.009-.222-.026l-.043-.01c-.026-.008-.051-.016-.076-.026l-.025-.01-.07-.031-.044-.025-.048-.03c-.045-.03-.088-.064-.129-.102l.082.07c-.029-.023-.056-.046-.082-.07L.272 10.406c-.363-.347-.363-.91 0-1.257.363-.347.95-.347 1.313 0l3.986 3.816V.889c0-.456.359-.832.82-.883z"
      />
    </defs>
    <mask id="icon_discount_b" fill="#fff">
      <use xlink:href="#icon_discount_a" />
    </mask>
    <use fill="#79838E" fill-rule="nonzero" xlink:href="#icon_discount_a" />
    <g :fill="color" class="icon-color" mask="url(#icon_discount_b)">
      <path d="M0 0H21.662V21.662H0z" transform="translate(-5 -3)" />
    </g>
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
